
import { useForm } from '@formspree/react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function ContactForm() {
    const[state, handleSubmit] = useForm('xpzggyag');

    const handleSubmission = async (e) => {
        // e.preventDefault();

        // Show "Submitting..." modal
        MySwal.fire({
            title: 'Submitting...',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });

        await handleSubmit(e);

        MySwal.close();
        console.log("Submitting: ", state.submitting);

        if (state.succeeded) {
            // If successful, close the modal
            MySwal.close();
            // If successful, show success modal
            MySwal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Your message has been submitted successfully.',
              confirmButtonText: 'OK',
            }).then(() => {
              // Clear form input values
              document.getElementById('contact-form').reset();
            });
        } else {
            
            // If there are errors, show error modal
            MySwal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'There was an error submitting your message. Please try again later.',
              confirmButtonText: 'OK',
            });

            // Log server-side validation errors
            console.log('Server-side Validation Errors:', state.errors);
        }
    };

        
    
    return (
        <>
            <div
                className="video fh5co-video"
                style={{ backgroundImage: "url(images/unsplash-1.jpg)" }}
            >
                <div className="overlay" />
            </div>
            <div className="choose">
                <h2>Contact</h2>
                <form 
                    id="contact-form"
                    name="my-contact-form"
                    acceptCharset="utf-8"
                    action="https://formspree.io/f/xpzggyag"
                    method="post"
                    onSubmit={handleSubmission}
                >
                    <div className="row form-group">
                        <div className="col-md-6">
                            <input
                                type="text"
                                id="fname"
                                name="First Name"
                                className="form-control"
                                placeholder="Your firstname"
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-6">
                            <input
                                type="text"
                                id="lname"
                                name="Last Name"
                                className="form-control"
                                placeholder="Your lastname"
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-12">
                            <input
                                type="text"
                                id="email"
                                name="Email"
                                className="form-control"
                                placeholder="Your email address"
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-12">
                            <input
                                type="text"
                                id="subject"
                                name="Subject"
                                className="form-control"
                                placeholder="Your subject of this message"
                            />
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-md-12">
                            <textarea
                                name="Message"
                                id="message"
                                cols={30}
                                rows={5}
                                className="form-control"
                                placeholder="Say something about/to me"
                                defaultValue={""}
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <input
                            type="submit"
                            defaultValue="Send Message"
                            className="btn btn-primary"
                        />
                    </div>
                </form>
            </div>
        </>

    );
}

export default ContactForm;